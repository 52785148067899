import React, { useState, useEffect } from "react";
import { RECORD_STATUS } from "@sarafhbk/react-audio-recorder";

function AudioRecorder({ audioRecorder }) {
  const {
    audioResult: audioResultFromHook,
    // timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    // errorMessage,
  } = audioRecorder;
  const [audioResult, setAudioResult] = useState("");

  useEffect(() => {
    setAudioResult(audioResultFromHook);
  }, [audioResultFromHook]);

  const resetAudioResult = () => {
    setAudioResult("");
  };

  return (
    <div className="audio-record-container">
      <div className="inner-container">
        <div className="buttons">
          {status !== "idle" && (
            <button
              className="btn-play"
              onClick={
                status === RECORD_STATUS.RECORDING
                  ? pauseRecording
                  : resumeRecording
              }
            >
              <i
                className={`fas fa-${
                  status === RECORD_STATUS.RECORDING ? "pause" : "play"
                }`}
              ></i>
            </button>
          )}
          <button className="btn-record" onClick={startRecording}>
            <i className={"fas fa-microphone"}></i>
          </button>
          {audioResult !== "" && status === "idle" && (
            <>
              <audio controls src={audioResult} />
              <button className="btn-reset" onClick={resetAudioResult}>
                <i className={"fa-solid fa-arrows-rotate"}></i>
              </button>
            </>
          )}
          {status !== "idle" && (
            <button className="btn-stop" onClick={stopRecording}>
              <i className={"fas fa-stop"}></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AudioRecorder;
